import { useContext } from "react";
import { ConsumptionContext } from "../../contexts/ConsumptionContext";
import { Button, Card, Checkbox, Dropdown, List, Table } from "antd";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";
import { useMediaQuery } from "react-responsive";
import { SettingOutlined } from "@ant-design/icons";

const ConsumptionRecords = () => {

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const {
        data, setOpenDetailModal, setModelDetail,
        setOpenDisableModal, setDisableModel,
        consumptionsSelected, setConsumptionsSelected,
        setConsumptionModel, setShowView,
        setOpenDisableLaundry
    } = useContext(ConsumptionContext);

    const getMenu = (value) => {

        const menu = {
            items: []
        };

        const optionShowDetail = {
            key: 'details', label: 'Ver Detalle', onClick: () => {
                setModelDetail(value);
                setOpenDetailModal(true);
            }
        };

        const optionPartialPayments = {
            key: 'partial_payments', label: 'Pagos Parciales', onClick: () => {
                setConsumptionModel(value);
                setShowView('partial_payments');
            }
        };

        const optionDisable = {
            key: 'disable', label: 'Eliminar', onClick: () => {
                setDisableModel(value);
                setOpenDisableModal(true);
            }
        };

        const optionDisableLaundry = {
            key: 'disable_laundry', label: 'Eliminar', onClick: () => {
                setDisableModel(value);
                setOpenDisableLaundry(true);
            }
        };

        const optionLaundryDetail = {
            key: 'laundry_detail', label: 'Detalle', onClick: () => {
                setConsumptionModel(value);
                setShowView('laundry_detail');
            }
        };

        if (value.type === 'LAUNDRY') menu.items.push(optionLaundryDetail);

        if (value.type === 'ROOM' || value.type === 'PRODUCT') {
            menu.items.push(optionShowDetail);
        }

        if (value.type === 'ROOM') menu.items.push(optionPartialPayments);

        if (value.paid === 0 && value.type === 'PRODUCT') menu.items.push(optionDisable);

        if (value.paid === 0 && value.type === 'LAUNDRY') menu.items.push(optionDisableLaundry);

        return menu;

    };

    const onCheckBox = (values, model) => {
        if (values.target.checked) {
            const tempConsumptionSelected = [...consumptionsSelected];
            const finded = tempConsumptionSelected.find(item => item.consumption_id === model.consumption_id);
            if (!finded) {
                tempConsumptionSelected.push(model);
                setConsumptionsSelected(tempConsumptionSelected);
            }
        } else {
            let tempConsumptionSelected = [...consumptionsSelected];
            tempConsumptionSelected = tempConsumptionSelected.filter(item => item.consumption_id !== model.consumption_id);
            setConsumptionsSelected(tempConsumptionSelected);
        }
    };

    return (
        <Card title={'Consumos registrados'} style={{ marginBottom: '20px' }}>
            {isMobile ?
                <>
                    <List
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(model, index) => (
                            <List.Item key={index}>
                                <Card bordered={false}>
                                    <div className="RowContainer">
                                        <div className="ColumnContainer">
                                            <div><strong>Seleccionar:</strong> <Checkbox onChange={(e) => onCheckBox(e, model)} disabled={model.paid === 1} /></div>
                                            <div><strong>Tipo:</strong> {model.type === 'ROOM' && 'HABITACIÓN'}{model.type === 'PRODUCT' && 'PRODUCTO'}{model.type === 'LAUNDRY' && 'LAVANDERÍA'}</div>
                                            <div><strong>Monto:</strong> {GeneralUtil.getMoney(model.amount)}</div>
                                            <div><strong>Pagado:</strong> {model.paid ? 'PAGADO' : 'PENDIENTE'}</div>
                                            <div><strong>Registrado:</strong> {DateUtil.ToSimpleString(model.created_at)}</div>
                                        </div>
                                        <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                            <Dropdown menu={getMenu(model)} trigger={['click']} >
                                                <Button icon={<SettingOutlined />}>Acciones</Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </> :
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedRowKeys, selectedRows) => {
                            setConsumptionsSelected(selectedRows);
                        },
                        getCheckboxProps: (record) => ({
                            disabled: record.paid === 1
                        })
                    }}
                    pagination={false}
                    bordered
                    dataSource={data}
                    rowKey={(record) => record.consumption_id}
                    columns={[
                        {
                            title: 'Tipo', width: 100, key: 'type', render: (model) => {
                                return (
                                    <>
                                        {model.type === 'ROOM' && 'HABITACIÓN'}
                                        {model.type === 'PRODUCT' && 'PRODUCTO'}
                                        {model.type === 'LAUNDRY' && 'LAVANDERÍA'}
                                    </>

                                );
                            }
                        },
                        {
                            title: 'Monto', width: 100, key: 'dates', render: (model) => {
                                return (
                                    <>{GeneralUtil.getMoney(model.amount)}</>
                                );
                            }
                        },
                        {
                            title: 'Pagado', width: 100, key: 'dates', render: (model) => {
                                return (
                                    <>{model.paid ? 'PAGADO' : 'PENDIENTE'}</>
                                );
                            }
                        },
                        {
                            title: 'Registrado', dataIndex: 'created_at', width: 200, key: 'created_at', render: (value) => {
                                return (<>{DateUtil.ToSimpleString(value)}</>);
                            }
                        },
                        {
                            title: 'Opciones', key: 'Acciones', width: 150, render: (value) => (
                                <Dropdown trigger={['click']} menu={getMenu(value)}>
                                    <Button icon={<SettingOutlined />}>Acciones</Button>
                                </Dropdown>
                            )
                        },
                    ]}
                />
            }
        </Card>
    );
};

export default ConsumptionRecords;