import { Button, Select, Table } from "antd";
import ProductService from "../services/ProductService";
import { useState } from "react";
import DateUtil from "../utils/DateUtil";
import dayjs from "dayjs";
import GuestService from "../services/GuestService";
import SaleService from "../services/SaleService";

const ReportPage = () => {

    const [donwloadReporteHuespedes, setDonwloadReporteHuespedes] = useState({
        month: dayjs().month() + 1,
        year: dayjs().year()
    });

    const [donwloadReporteVentas, setDonwloadReporteVentas] = useState({
        month: dayjs().month() + 1,
        year: dayjs().year()
    });

    const productGetAllService = () => {
        ProductService.getAll().then(async (response) => {

            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'productos.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(url);

        }).catch(error => {
            console.log(error);
        });
    };

    const getRecordsByMonthAndYear = () => {
        GuestService.getRecordsByMonthAndYear(donwloadReporteHuespedes.month, donwloadReporteHuespedes.year).then(async (response) => {

            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'huespedes.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(url);

        });
    };

    const salesByMonthAndYearService = () => {
        SaleService.getRecordsByMonthAndYear(donwloadReporteVentas.month, donwloadReporteVentas.year).then(async (response) => {

            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'ventas.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(url);

        });
    };

    return (
        <>
            <h1>REPORTES</h1>
            <div>

                <Table
                    pagination={false}
                    bordered
                    dataSource={[
                        {
                            id: 0,
                            report_name: 'REPORTE DE PRODUCTOS',
                        },
                        {
                            id: 1,
                            report_name: 'REPORTE DE HUESPEDES',
                        },
                        {
                            id: 2,
                            report_name: 'REPORTE DE VENTAS',
                        }
                    ]}
                    rowKey={(record) => record.id}
                    columns={[
                        { title: 'REPORTES', key: 'report_name', dataIndex: 'report_name' },
                        {
                            title: 'OPCIONES', key: 'options', render: (value) => (
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    {value.id === 0 && (
                                        <Button onClick={() => { productGetAllService() }}>DESCARGAR EXCEL</Button>
                                    )}
                                    {value.id === 1 && (
                                        <>
                                            <Select onChange={(value) => {
                                                setDonwloadReporteHuespedes({ ...donwloadReporteHuespedes, month: value });
                                            }} value={donwloadReporteHuespedes.month} options={DateUtil.GetMonthsForSelect()} style={{ width: '150px' }} />
                                            <Select onChange={(value) => {
                                                setDonwloadReporteHuespedes({ ...donwloadReporteHuespedes, year: value });
                                            }} value={donwloadReporteHuespedes.year} options={DateUtil.GetYearsForSelect()} style={{ width: '150px' }} />
                                            <Button onClick={() => { getRecordsByMonthAndYear() }}>DESCARGAR EXCEL</Button>
                                        </>
                                    )}
                                    {value.id === 2 && (
                                        <>
                                            <Select onChange={(value) => {
                                                setDonwloadReporteVentas({ ...donwloadReporteVentas, month: value });
                                            }} value={donwloadReporteVentas.month} options={DateUtil.GetMonthsForSelect()} style={{ width: '150px' }} />
                                            <Select onChange={(value) => {
                                                setDonwloadReporteVentas({ ...donwloadReporteVentas, year: value });
                                            }} value={donwloadReporteVentas.year} options={DateUtil.GetYearsForSelect()} style={{ width: '150px' }} />
                                            <Button onClick={() => { salesByMonthAndYearService() }}>DESCARGAR EXCEL</Button>
                                        </>
                                    )}
                                </div>
                            )
                        }
                    ]}
                />

            </div>
        </>
    );

};

export default ReportPage;