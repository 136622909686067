import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import BookingService from "../services/BookingService";
import ParseHelper from "../helpers/ParseHelper";
import RoomService from "../services/RoomService";
import CustomerService from "../services/CustomerService";
import dayjs from "dayjs";
import RoomTypeHelper from "../helpers/RoomTypeHelper";
import { defaultSaleResumen } from "../config/constants";

export const BookingFormContext = createContext();

export const BookingFormProvider = ({ children, booking, onBack }) => {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [customerId, setCustomerId] = useState(null);

    const [rooms, setRooms] = useState([]);

    const [items, setItems] = useState(defaultSaleResumen);

    const createBooking = (values) => {
        BookingService.create(values).then(() => {

            message.success('Se agregó la reserva.');

            form.resetFields();

            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });

            setItems(defaultSaleResumen);

            if (onBack) onBack();

        }).catch(error => {

            console.log(error);

            message.error('Ocurrion un error con el servicio.');

        }).finally(() => {
            setLoading(false);
        });
    };

    const updateBooking = (values) => {
        BookingService.update(booking.id, values).then(() => {

            message.success('Se actulizó la reserva.');

            if (onBack) onBack();

        }).catch(error => {

            console.log(error);

            message.error('Ocurrion un error con el servicio.');

        }).finally(() => {
            setLoading(false);
        });
    };

    const roomAvailable = (values, showMessage) => {
        RoomService.available(values).then((response) => {
            const options = [];
            response.map((element) => {
                options.push({
                    value: element.id,
                    label: `${element.room_number} - ${RoomTypeHelper.getDescription(element.room_type)}`,
                    price: element.room_price
                });
            });
            if (showMessage) {
                if (options.length > 0) {
                    message.success('Hay habitaciones disponibles.');
                } else {
                    message.warning('No hay habitaciones disponibles.');
                }
            }
            setRooms(options);
        }).catch(err => {
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            if (showMessage) {
                form.setFieldValue('habitacion', '');
                form.setFieldValue('habitacionPrecio', '');
            }
        });
    };

    const getCustomer = (values) => {
        CustomerService.getByDocument(values).then(response => {
            setCustomerId(response.id);
            form.setFieldsValue(response);
            message.success('Se encontró al cliente');
        }).catch((err) => {
            setCustomerId(null);
            form.setFieldsValue({ name: '', lastname: '', gender: '', phone: '', address: '', company: '' });
            message.warning('No se encontró al cliente');
        });
    };

    const updatePrice = (price) => {
        const count = items[0].children != 0 ? items[0].children : 1;
        setItems([
            { key: '1', label: 'Noches', children: items[0].children },
            { key: '2', label: 'Precio Habitación', children: `S/ ${price.toFixed(2)}` },
            { key: '3', label: 'Total', children: `S/ ${(price * count).toFixed(2)}` }
        ]);
    };

    const verifyAvailability = (showMessage) => {

        const { start_day, start_time, end_day, end_time } = form.getFieldsValue();

        if (!start_day || !start_time || !end_day || !end_time) {
            message.warning('Por favor, complete las fechas y tiempos de llegada y salida.');
            setRooms([]);
            setItems([
                { key: '1', label: 'Noches', children: '0' },
                { key: '2', label: 'Precio Habitación', children: 'S/ 0.00' },
                { key: '3', label: 'Total', children: 'S/ 0.00' }
            ]);
            form.setFieldValue('habitacion', '');
            form.setFieldValue('habitacionPrecio', '');
            return;
        }

        const start_date_dayjs = dayjs(`${start_day.format('YYYY-MM-DD')} ${start_time.format('HH:mm:00')}`);
        const end_date_dayjs = dayjs(`${end_day.format('YYYY-MM-DD')} ${end_time.format('HH:mm:00')}`);

        if (start_date_dayjs.isBefore(dayjs())) {
            message.error('La fecha y hora de llegada de la reserva tiene que ser mayor a la actual.');
            return;
        }

        if (end_date_dayjs.isBefore(start_date_dayjs)) {
            message.error('La fecha y hora de salida tienen que ser después de la fecha y hora de llegada.');
            return;
        }

        const start_date = start_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);
        const end_date = end_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const start_date_temp = dayjs(`${start_day.format('YYYY-MM-DD')} 00:00:00`);

        const end_date_temp = dayjs(`${end_day.format('YYYY-MM-DD')} 00:00:00`);

        const diferenciaEnDias = end_date_temp.diff(start_date_temp, 'day');

        setItems([
            { key: '1', label: 'Noches', children: diferenciaEnDias },
            { key: '2', label: 'Precio Habitación', children: 'S/ 0.00' },
            { key: '3', label: 'Total', children: 'S/ 0.00' }
        ]);

        let booking_id = '';

        if (booking) {
            booking_id = ParseHelper.parseIntegerOrNull(booking.id)
        }

        roomAvailable({ start_date, end_date, booking_id }, showMessage);
    };

    useEffect(() => {

        if (booking) {

            const start_date = dayjs(booking.start_date);
            const end_date = dayjs(booking.end_date);

            form.setFieldValue('start_day', dayjs(start_date.format('YYYY-MM-DD')));
            form.setFieldValue('start_time', dayjs(start_date.format('HH:mm'), 'HH:mm'));
            form.setFieldValue('end_day', dayjs(end_date.format('YYYY-MM-DD')));
            form.setFieldValue('end_time', dayjs(end_date.format('HH:mm'), 'HH:mm'));

            verifyAvailability();

            form.setFieldValue('habitacion', booking.room_id);
            form.setFieldValue('habitacionPrecio', booking.room_price);
            form.setFieldValue('document_type', booking.document_type);
            form.setFieldValue('document_number', booking.document_number);
            form.setFieldValue('name', booking.name);
            form.setFieldValue('lastname', booking.lastname);
            form.setFieldValue('gender', booking.gender);
            form.setFieldValue('phone', booking.phone);
            form.setFieldValue('address', booking.address);
            form.setFieldValue('company', booking.company);

            const start_date_temp = dayjs(start_date.format('YYYY-MM-DD'));
            const end_date_temp = dayjs(end_date.format('YYYY-MM-DD'));

            const diferenciaEnDias = end_date_temp.diff(start_date_temp, 'days');

            setItems([
                { key: '1', label: 'Noches', children: diferenciaEnDias },
                { key: '2', label: 'Precio Habitación', children: `S/ ${booking.room_price}` },
                { key: '3', label: 'Total', children: `S/ ${booking.total}` }
            ]);

            setCustomerId(booking.customer_id);

        } else {
            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });
        }
    }, []);
    return (
        <BookingFormContext.Provider value={{
            form, booking,
            loading, setLoading,
            customerId,
            rooms, setRooms,
            items, setItems,
            createBooking, updateBooking,
            verifyAvailability, getCustomer, updatePrice
        }}>
            {children}
        </BookingFormContext.Provider>
    );
};