import { Form, message } from "antd";
import { createContext, useEffect, useState } from "react";
import GuestService from "../services/GuestService";
import RoomService from "../services/RoomService";
import RoomTypeHelper from "../helpers/RoomTypeHelper";
import CustomerService from "../services/CustomerService";
import ParseHelper from "../helpers/ParseHelper";
import dayjs from "dayjs";
import ValidationHelper from "../helpers/ValidationHelper";
import { defaultSaleResumen } from "../config/constants";

export const GuestFormContext = createContext();

export const GuestFormProvider = ({ children, guest, booking, onBack }) => {

    const [form] = Form.useForm();

    const [formSale] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [customerId, setCustomerId] = useState(null);

    const [rooms, setRooms] = useState([]);

    const [openSaleModal, setOpenSaleModal] = useState(false);

    const [disableCustomerInputs, setDisableCustomerInputs] = useState(false);

    const [items, setItems] = useState(defaultSaleResumen);

    const createGuest = (values) => {
        GuestService.create(values).then(() => {
            message.success('Se registro al huesped.');
            form.resetFields();
            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });
            setItems(defaultSaleResumen);
            if (ValidationHelper.hasData(onBack)) {
                onBack();
            }
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const createGuestWithSale = (body) => {

        const createGuestValues = { ...body, ...formSale.getFieldsValue() };

        GuestService.createWithSale(createGuestValues).then(() => {

            message.success('Se registro al huesped.');

            setOpenSaleModal(false);

            form.resetFields();

            formSale.resetFields();

            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });

            setItems(defaultSaleResumen);

            if (ValidationHelper.hasData(onBack)) onBack();

        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const createGuestByBooking = (values) => {
        GuestService.createByBooking(values).then(() => {
            message.success('Se registro al huesped.');
            form.resetFields();
            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });
            setItems(defaultSaleResumen);
            if (ValidationHelper.hasData(onBack)) {
                onBack();
            }
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const createGuestByBookingWithSale = (body) => {

        const createGuestValues = { ...body, ...formSale.getFieldsValue() };

        GuestService.createByBookingWithSale(createGuestValues).then(() => {

            message.success('Se registro al huesped.');

            setOpenSaleModal(false);

            form.resetFields();

            formSale.resetFields();

            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });

            setItems(defaultSaleResumen);

            if (ValidationHelper.hasData(onBack)) onBack();

        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });

    };

    const updateGuest = (values) => {
        GuestService.updateData(guest.id, values).then(() => {
            message.success('Se actulizaron los datos del huesped.');
            if (ValidationHelper.hasData(onBack)) {
                onBack();
            }
        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            setLoading(false);
        });
    };

    const roomAvailable = (values, showMessage) => {
        RoomService.available(values).then(response => {

            const options = [];

            console.log(response);

            response.map(element => {

                if (element.status === 'LIMPIO') {
                    options.push({
                        value: element.id,
                        label: `${element.room_number} - ${RoomTypeHelper.getDescription(element.room_type)}`,
                        price: element.room_price
                    });
                }

            });

            if (showMessage) {
                if (options.length > 0) {
                    message.success('Hay habitaciones disponibles.');
                } else {
                    message.warning('No hay habitaciones disponibles.');
                }
            }

            setRooms(options);

        }).catch(error => {
            console.log(error);
            message.error('Ocurrion un error con el servicio.');
        }).finally(() => {
            if (showMessage) {
                form.setFieldValue('habitacion', '');
                form.setFieldValue('habitacionPrecio', '');
            }
        });
    };

    const getCustomer = (values) => {
        CustomerService.getByDocument(values).then(response => {
            setCustomerId(response.id);
            form.setFieldsValue(response);
            message.success('Se encontró al cliente');
        }).catch((error) => {
            console.log(error);
            setCustomerId(null);
            form.setFieldsValue({ name: '', lastname: '', gender: '', phone: '', address: '', company: '' });
            message.warning('No se encontró al cliente');
        });
    };

    const updatePrice = (price) => {

        const count = items[0].children != 0 ? items[0].children : 1;

        setItems([
            { key: '1', label: 'Noches', children: items[0].children },
            { key: '2', label: 'Precio Habitación', children: `S/ ${price.toFixed(2)}` },
            { key: '3', label: 'Total', children: `S/ ${(price * count).toFixed(2)}` }
        ]);

    };

    const verifyAvailability = (showMessage) => {

        const { start_day, start_time, end_day, end_time } = form.getFieldsValue();

        if (!start_day || !start_time || !end_day || !end_time) {
            message.warning('Por favor, complete las fechas y tiempos de llegada y salida.');
            setRooms([]);
            setItems(defaultSaleResumen);
            form.setFieldValue('habitacion', '');
            form.setFieldValue('habitacionPrecio', '');
            return;
        }

        const start_date_dayjs = dayjs(`${start_day.format('YYYY-MM-DD')} ${start_time.format('HH:mm:00')}`);

        const end_date_dayjs = dayjs(`${end_day.format('YYYY-MM-DD')} ${end_time.format('HH:mm:00')}`);

        if (dayjs().isBefore(start_date_dayjs)) {
            message.error('Si la fecha de inicio es mayor a la fecha actual, registra una reserva.');
            return;
        }

        if (end_date_dayjs.isBefore(start_date_dayjs)) {
            message.error('La fecha y hora de salida tienen que ser después de la fecha y hora de llegada.');
            return;
        }

        const start_date = start_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const end_date = end_date_dayjs.format(`YYYY-MM-DD HH:mm:00`);

        const start_date_temp = dayjs(`${start_day.format('YYYY-MM-DD')} 00:00:00`);

        const end_date_temp = dayjs(`${end_day.format('YYYY-MM-DD')} 00:00:00`);

        const diferenciaEnDias = end_date_temp.diff(start_date_temp, 'day');

        setItems([
            { key: '1', label: 'Noches', children: diferenciaEnDias },
            { key: '2', label: 'Precio Habitación', children: 'S/ 0.00' },
            { key: '3', label: 'Total', children: 'S/ 0.00' }
        ]);

        let guest_id = '';

        if (guest) {
            guest_id = ParseHelper.parseIntegerOrNull(guest.id)
        }

        roomAvailable({ start_date, end_date, guest_id }, showMessage);

    };

    const loadDataByGuest = () => {

        const start_date = dayjs(guest.start_date);
        const end_date = dayjs(guest.end_date);

        form.setFieldValue('start_day', dayjs(start_date.format('YYYY-MM-DD')));
        form.setFieldValue('start_time', dayjs(start_date.format('HH:mm'), 'HH:mm'));
        form.setFieldValue('end_day', dayjs(end_date.format('YYYY-MM-DD')));
        form.setFieldValue('end_time', dayjs(end_date.format('HH:mm'), 'HH:mm'));

        verifyAvailability();

        form.setFieldValue('habitacion', guest.room_id);
        form.setFieldValue('habitacionPrecio', guest.room_price);
        form.setFieldValue('document_type', guest.document_type);
        form.setFieldValue('document_number', guest.document_number);
        form.setFieldValue('name', guest.name);
        form.setFieldValue('lastname', guest.lastname);
        form.setFieldValue('gender', guest.gender);
        form.setFieldValue('phone', guest.phone);
        form.setFieldValue('address', guest.address);
        form.setFieldValue('company', guest.company);

        setDisableCustomerInputs(true);

        form.setFieldValue('guest_vehicle_plate', guest.guest_vehicle_plate);
        form.setFieldValue('guest_observations', guest.guest_observations);

        const start_date_temp = dayjs(start_date.format('YYYY-MM-DD'));
        const end_date_temp = dayjs(end_date.format('YYYY-MM-DD'));

        const diferenciaEnDias = end_date_temp.diff(start_date_temp, 'days');

        setItems([
            { key: '1', label: 'Noches', children: diferenciaEnDias },
            { key: '2', label: 'Precio Habitación', children: `S/ ${guest.room_price}` },
            { key: '3', label: 'Total', children: `S/ ${guest.total}` }
        ]);

        setCustomerId(guest.customer_id);

    };

    const loadDataByBooking = () => {

        const start_date = dayjs(booking.start_date);
        const end_date = dayjs(booking.end_date);

        form.setFieldValue('start_day', dayjs(start_date.format('YYYY-MM-DD')));
        form.setFieldValue('start_time', dayjs(start_date.format('HH:mm'), 'HH:mm'));
        form.setFieldValue('end_day', dayjs(end_date.format('YYYY-MM-DD')));
        form.setFieldValue('end_time', dayjs(end_date.format('HH:mm'), 'HH:mm'));

        verifyAvailability();

        form.setFieldValue('habitacion', booking.room_id);
        form.setFieldValue('habitacionPrecio', booking.room_price);
        form.setFieldValue('document_type', booking.document_type);
        form.setFieldValue('document_number', booking.document_number);
        form.setFieldValue('name', booking.name);
        form.setFieldValue('lastname', booking.lastname);
        form.setFieldValue('gender', booking.gender);
        form.setFieldValue('phone', booking.phone);
        form.setFieldValue('address', booking.address);
        form.setFieldValue('company', booking.company);

        setDisableCustomerInputs(true);

        const start_date_temp = dayjs(start_date.format('YYYY-MM-DD'));
        const end_date_temp = dayjs(end_date.format('YYYY-MM-DD'));

        const diferenciaEnDias = end_date_temp.diff(start_date_temp, 'days');

        setItems([
            { key: '1', label: 'Noches', children: diferenciaEnDias },
            { key: '2', label: 'Precio Habitación', children: `S/ ${booking.room_price}` },
            { key: '3', label: 'Total', children: `S/ ${booking.total}` }
        ]);

        setCustomerId(booking.customer_id);

    };

    useEffect(() => {

        if (ValidationHelper.hasData(guest)) {
            loadDataByGuest();
            return;
        }

        if (ValidationHelper.hasData(booking)) {
            loadDataByBooking();
            return;
        }

        if (!ValidationHelper.hasData(guest) && !ValidationHelper.hasData(booking)) {
            form.setFieldsValue({ start_time: dayjs('12:00', 'HH:mm'), end_time: dayjs('12:00', 'HH:mm') });
            return;
        }

    }, []);

    return (
        <GuestFormContext.Provider value={{
            form, guest, disableCustomerInputs, booking,
            loading, setLoading,
            customerId,
            rooms, setRooms,
            items, setItems,
            createGuest, updateGuest, createGuestByBooking,
            verifyAvailability, getCustomer, updatePrice,
            openSaleModal, setOpenSaleModal,
            createGuestWithSale, createGuestByBookingWithSale,
            formSale
        }}>
            {children}
        </GuestFormContext.Provider>
    );

};