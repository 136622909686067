import { Button, Card, Dropdown, Modal } from "antd";
import { useContext } from "react";
import { BookingCalendarContext } from "../../contexts/BookingCalendarContext";
import DateUtil from "../../utils/DateUtil";
import GeneralUtil from "../../utils/GeneralUtil";
import { SettingOutlined } from "@ant-design/icons";

const BookingCalendarModal = () => {

    const {
        model, setModel,
        open, setOpen,
        setModelBooking, setShowView,
        setShowDeleteBookingModal
    } = useContext(BookingCalendarContext);

    const onCancel = () => {
        setOpen(false);
        setModel(null);
    };

    const getMenu = (value) => {

        const menu = {
            items: []
        };

        const optionEdit = {
            key: 'edit', label: 'Editar', onClick: () => {
                setModelBooking(value);
                setShowView('booking_form');
                setOpen(false);
            }
        };

        const optionConsumptions = {
            key: 'consumptions', label: 'Consumos', onClick: () => {
                setModelBooking(value);
                setShowView('consumptions');
                setOpen(false);
            }
        };

        const optionDelete = {
            key: 'delete', label: 'Eliminar', onClick: () => {
                setModelBooking(value);
                setOpen(false);
                setShowDeleteBookingModal(true);
            }
        };

        menu.items.push(optionEdit);

        menu.items.push(optionConsumptions);

        menu.items.push(optionDelete);

        return menu;

    };

    return (
        <>
            {model && (
                <Modal
                    width={1000}
                    open={open}
                    onCancel={onCancel}
                    title={'LISTA DE RESERVAS'}
                    footer={null}
                >
                    <div className="BookingCardList" style={{ marginTop: '10px' }}>
                        {model.map((booking, index) => (
                            <Card key={index} title={<div>Habitación: {booking.room_number}</div>}>
                                <div style={{ marginBottom: '20px' }}>
                                    <div>Cuenta: {booking.customer_account_code}</div>
                                    <div>Ingreso: {DateUtil.ToSimpleString(booking.start_date)}</div>
                                    <div>Salida: {DateUtil.ToSimpleString(booking.end_date)}</div>
                                    <div>Cliente: {booking.name} {booking.lastname}</div>
                                    <div>Monto: {GeneralUtil.getMoney(booking.total)}</div>
                                    <div>Estado: {booking.booking_status}</div>
                                    <div>Registrado: {DateUtil.ToSimpleString(booking.created_at)}</div>
                                </div>
                                <div className="ColumnContainer ColumnContainerVentas" style={{ width: '100%' }}>
                                    <Dropdown menu={getMenu(booking)} trigger={['click']} >
                                        <Button icon={<SettingOutlined />}>Acciones</Button>
                                    </Dropdown>
                                </div>
                            </Card>
                        ))}
                    </div>
                </Modal>
            )}
        </>
    );
}

export default BookingCalendarModal;